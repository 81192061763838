.mainContainerMobileNav {
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(125, 114, 42, 0.372);
    width: 70vw;
    height: 100vh;
    z-index: 777;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.mainContainerMobileNav ul {
    margin: 3vh auto;
}
.mainContainerMobileNav li {
    font-size: 4rem;
    margin: 4vh auto;
    cursor: pointer;
}

.socalPicsMobileNav {
    padding: 1vh 1vw;
    margin: 0 10vw;
    height: 7vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.fac,
.inst,
.github,
.twitter,
.linkedin {
    width: 4rem;
    border-radius: 15px;
    cursor: pointer;
}
.fac:hover,
.inst:hover,
.twitter:hover,
.linkedin:hover {
    background-color: rgb(3, 43, 43);
}
.github:hover {
    background-color: rgb(3, 43, 43);
}
.github,
.twitter {
    border-radius: 200px;
}

@media (max-width: 800px) {
    .mainContainerMobileNav ul {
        padding-top: 5vh;
        margin: 3vh auto;
    }
    .mainContainerMobileNav li {
        font-size: 3rem;
        margin: 6vh auto;
    }
    
    .socalPicsMobileNav {
        height: 7vh;
        /* background-color: aliceblue;  */
        margin: 0 0 2vh 0;
    }
    
    .fac,
    .inst,
    .github,
    .twitter {
        width: 3rem;
    }
}
@media (max-width: 400px) {
    .socalPicsMobileNav {
        margin: 0 2vw;
    }
    
    .fac,
    .inst,
    .github,
    .twitter {
        width: 2.5rem;
    }
}
@media(max-width: 300px) {
    .mainContainerMobileNav ul {
        padding-top: 5vh;
        margin: 3vh auto;
    }
    .mainContainerMobileNav li {
        font-size: 2rem;
        margin: 6vh auto;
    }
    
    .socalPicsMobileNav {
        height: 7vh;
    }
    
    .fac,
    .inst,
    .github,
    .twitter {
        width: 2.5rem;
    }
}