.mainContainer {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 11vw;
    border-left: rgb(127, 99, 5) 7px solid;
    display: flex;
    flex-direction: column;
}

.ul {
    margin: auto 0;
    padding: 0rem;
    display: flex;
    flex-direction: column;
}

.li {
    margin: 0.5rem 0.5rem;
    background-color: rgba(34, 32, 19, 0.372);
    border-radius: 10px;
    padding: 0.7rem 0.5rem;
    font-size: 1.5rem;
    color: rgb(176, 136, 7);
    cursor: pointer;
    border-top: rgb(176, 136, 7) 1px solid;
    border-bottom: rgb(176, 136, 7) 1px solid;
    border-left: transparent;
    border-right: transparent;
}
.li:hover,
.a:hover {
    transform: translateY(0.2rem);
}
.a {
    position: relative;
    bottom: -18rem;
    right: 0;
    margin: 0;
    background-color: rgba(18, 22, 162, 0.139);
    border-radius: 10px;
    padding: 0.5rem 0.5rem;
    font-size: 1.5rem;
    text-decoration: none;
    color: rgb(176, 136, 7);
    cursor: pointer;
}

.socalPics {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: fixed;
    bottom: 1rem;
    width: 10.5%;
    margin: 0 0.25rem;
}
.socalPics a {
    width: 2rem;
    display: flex;
    flex-direction: row;
}
.fac,
.inst,
.github,
.twitter,
.linkedin {
    width: 2rem;
    border-radius: 200px;
    cursor: pointer;
}
.fac:hover,
.inst:hover,
.twitter:hover,
.github:hover,
.linkedin:hover {
    background-color: rgb(3, 43, 43);
}


@media (max-width: 1200px) {
    .socalPics {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .fac,
    .inst,
    .github,
    .twitter,
    .linkedin  {
        width: 3rem;
        padding: 0.4rem 0;
    }

    .mainContainer {
        display: none;
    }

}

