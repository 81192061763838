.aboutMain {
    position: fixed;
    top: 4vh;
    left: 5vw;
    width: 80vw;
    height: 75vh;
    box-sizing: border-box;
    background-color: rgba(34, 32, 19, 0.5);
    margin: auto;
    padding: 0 2vw;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 70% 30%;
    transform: translateX(-101%);
}
.aboutBlock1 {
    margin: 0 auto;
    padding: 2vh 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.aboutBlock1 h1 {
    text-align: left;
    padding-left: 4vw;
    font-size: 2rem;
    margin: 4vh auto;    
}
.aboutBlock1_desc {
    height: 100%;
}
.aboutBlock1_descP {
    box-sizing: border-box;
    padding: 0.3vh 3vw;
    font-size: 1.5rem;
    text-align: left;
    line-height: 2.5vh;
}
.aboutBlock1 button {
    margin: auto;
    margin-top: 7vh;
    padding: 0.7vh 0.7vw;
    font-size: 1.7rem;
    font-weight: 700;
    border-radius: 10px;
}

.aboutBlock2 {
    border-left: rgb(127, 99, 5) 7px solid;
    margin: 0 auto;
    padding: 2vh 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.aboutBlock2 ul {
    margin: 0 auto;
    
}
.aboutBlock2 h1 {
    font-size: 2rem;
    margin: 4vh auto;    
}
.skils {
    height: 100%;
}
.aboutBlock2 li {
    box-sizing: border-box;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
    line-height: 3vh;
}
.react_parts li {
 font-size: 1.1rem;
 font-weight: 400;   
}

.buttonAbout {
    position: relative;
    top: 5vh;
    left: 0vw;
    margin: auto;
    width: 17rem;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    cursor: pointer;
}
.triangl1About {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 5rem solid rgba(50, 43, 8, 0.586);
    border-right: 17rem solid transparent;
}
.button__descrAbout {
    position: relative;
    font-size: 1.5rem;
    width: 17rem;
    top: 0rem;
    left: 0;
    color: rgb(173, 141, 36);
    z-index: 5;
    background-color: transparent;
    border: none;
}
.button__descrAbout:hover {
    color: white;
    background-color: transparent;
}
.triangl2About {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-right: 17rem solid rgba(6, 41, 41, 0.548);
    border-top: 5rem solid transparent;
}
@media(max-width: 1260px) {
    .aboutMain {
        left: 10vw;
    }
    .aboutMain h1 {
        font-size: 1.8rem;
        margin: 1vh 1vw;
    }
    .aboutBlock1_descP {
        padding: 0.3vh 3vw;
        font-size: 1.5rem;
        text-align: left;
        line-height: 1.8vh;
    }
    .aboutBlock1 button {
        margin-top: 1.4vh;
        padding: 0.3vh 0.3vw;
        font-size: 1.4rem;
        font-weight: 700;
        border-radius: 10px;
    }
    
    .aboutBlock2 li {
        font-size: 1.2rem;
        text-align: left;
        line-height: 3vh;
    }
    .react_parts li {
        font-size: 1rem;
        font-weight: 300;   
    }
}


@media(max-width: 800px) {
    .aboutMain {
        top: 3vh;
    }
    .aboutMain h1 {
        font-size: 1.5rem;
        margin: 1vh 1vw;
    }
    .aboutBlock1_descP {
        padding: 0.3vh 3vw;
        font-size: 1.2rem;
        text-align: left;
        line-height: 1.8vh;
    }
    .react_parts li {
        font-size: 1rem;
        font-weight: 300;   
        padding-inline-start: 0;
    }
}
@media (max-width: 600px) {
    .aboutMain {
        transform: translateX(-95.5%);
        top: 3vh;
        width: 90vw;
        height: 75vh;
    }
    ul {
        padding-inline-start: 3vw;
    }
    .aboutMain h1 {
        font-size: 1.2rem;
    }
    .aboutBlock1_descP {
        font-size: 1rem;
        text-align: left;
    }
    .aboutBlock2 {
        padding: 2vh 0;
        width: 100%;
    }    
    .aboutBlock2 li {
        font-size: 1rem;
        text-align: left;
        line-height: 3vh;
    }
    .react_parts {
        font-size: 0.5rem;
        font-weight: 200;   
        padding-inline-start: 2vw;
    }
    .react_parts li {
        font-size: 0.8rem;
        font-weight: 200;   
    }
    .buttonAbout {
        top: 5vh;
        left: 0vw;
        margin: auto;
        width: 12rem;
        height: 3rem;
    }
    .triangl1About {
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 3rem solid rgb(42, 35, 4);
        border-right: 12rem solid transparent;
    }
    .button__descrAbout {
        position: relative;
        font-size: 1rem;
        width: 17rem;
        top: 0rem;
        left: 0;
        color: rgb(176, 136, 7);
        z-index: 5;
        background-color: transparent;
        border: none;
    }
    .button__descrAbout:hover {
        color: white;
        background-color: transparent;
    }
    .triangl2About {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-right: 12rem solid rgb(3, 43, 43);
        border-top: 3rem solid transparent;
    }
}
@media (max-width: 400px) {
    .aboutMain {
        top: 3vh;
        width: 90vw;
        height: 75vh;
    }
    ul {
        padding-inline-start: 3vw;
    }
    .aboutMain h1 {
        font-size: 1rem;
    }
    .aboutBlock1_descP {
        font-size: 0.7rem;
        text-align: left;
        font-weight: lighter;
        line-height: 2vh;
    }
    .aboutBlock2 {
        border-left: rgb(127, 99, 5) 7px solid;
        margin: 0 auto;
        padding: 2vh 0;
        width: 100%;
    }    
    .aboutBlock2 li {
        font-size: 0.8rem;
        text-align: left;
        line-height: 3vh;
    }
    .react_parts {
        font-weight: 200;   
        padding-inline-start: 2vw;
    }
    .react_parts li {
        font-size: 0.7rem;
        font-weight: 200;
    }
    .buttonAbout {
        top: 5vh;
        left: 0vw;
        margin: auto;
        width: 12rem;
        height: 3rem;
    }
    .triangl1About {
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 3rem solid rgb(42, 35, 4);
        border-right: 12rem solid transparent;
    }
    .button__descrAbout {
        position: relative;
        font-size: 1rem;
        width: 17rem;
        top: 0rem;
        left: 0;
        color: rgb(176, 136, 7);
        z-index: 5;
        background-color: transparent;
        border: none;
    }
    .button__descrAbout:hover {
        color: white;
        background-color: transparent;
    }
    .triangl2About {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-right: 12rem solid rgb(3, 43, 43);
        border-top: 3rem solid transparent;
    }
}
@media(max-width: 300px) {
    .aboutMain {
        top: 3vh;
        width: 90vw;
        height: 75vh;
    }
    .aboutBlock1 {
        width: 50vw;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        letter-spacing: 0.05rem;
    }
    .aboutBlock1_descP {
        font-size: 0.65rem;
        line-height: 1.8vh;
        font-weight: 400;
    }
    .triangl1About {
        border-top: 2rem solid rgb(42, 35, 4);
        border-right: 8rem solid transparent;
    }
    .button__descrAbout {
        font-size: 0.7rem;
        width: 10rem;
        top: -0.3rem;
        left: -4vw;
    }
    .triangl2About {
        border-right: 8rem solid rgb(3, 43, 43);
        border-top: 2rem solid transparent;
    }
    .aboutBlock2 li {
        font-size: 0.7rem;
        text-align: left;
        line-height: 3vh;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        letter-spacing: 0.05rem;
        font-weight: 400;
    }
    .react_parts {
        font-weight: 200;   
        padding-inline-start: 2vw;
    }
    .react_parts li {
        font-size: 0.6rem;
        font-weight: 400;
    }
}