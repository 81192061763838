.App {
    background-image: linear-gradient(rgba(5.1, 23.5, 23.5, 0.933), rgba(5.1, 23.5, 23.5, 0.933)), url("./img/backgr.jpg");
    background-size: cover;
    text-align: center;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 90% 10%;
    grid-template-rows: 10% 85% 5%;
    color: rgb(173, 141, 36);
    overflow: hidden;
}

a {
    color: rgb(176, 136, 7);
    text-decoration: none;
}
button {
    background-color: rgb(176, 136, 7);
    color: whitesmoke;
    cursor: pointer;
}
button:hover {
    background-color: whitesmoke;
    color: rgb(176, 136, 7);
}
button:active {
  transform:translateY(0.1rem);
}
li {
    list-style: none;
}

section {
    margin: auto 0;
}


.App-notlink {
    font-size: 3rem;
    color: goldenrod;
}
.App-link {
    font-size: 5rem;
    color: #14c5b7;
}
.App-link:active {
    color: #282c34;
}

