.mainn {
    position: fixed;
    top: 4vh;
    left: 5vw;
    width: 80vw;
    height: 75vh;
    box-sizing: border-box;
    background-color: rgba(34, 32, 19, 0.5);
    padding: 1vh 0;
    margin: auto;
    border-radius: 15px;
    transform: translateX(-101%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.mainn h1 {
    font-size: 3rem;
    padding-top: 1vh;
    margin-bottom: 0;
}

.mainn h4 {
    font-weight: 100;
    margin-bottom: 0;
    padding-bottom: 1vh;
}

.block1 {
    padding: 1vh 0;
    padding-bottom: 5vh;
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.image {
  width: 100%;
}
.image img {
    width: 37vw;
    border-radius: 10px;
    cursor: pointer;
    border: 3px solid rgb(116, 90, 7);
}

.mainn img:hover {
    transform: scale(1.01);
}

.descript {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
}

.mainn p {
    width: 30vw;
    text-align: center;
    font-size: 1.2rem;
}


@media (max-width: 1250px) {
  .mainn {
      left: 10vw;
      top: 3vh;
      height: 80vh;
  }
  .mainn img {
    width: 50%;
    border-radius: 5px;
  }
  .block1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .mainn h1 {
    font-size: 2.3rem;
    padding-top: 1vh;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .descript p{
    width: 80%;
  }
  .work2 {
    border-top: rgb(127, 99, 5) 7px solid;
    width: 100%;
    height: 100%;
    padding-top: 2vh;
  }
}
@media (max-width: 1000px) {
  .mainn {
      left: 10vw;
      top: 3vh;
      height: 80vh;
  }
  .mainn img {
    width: 60%;
    border-radius: 5px;
  }
  .block1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .mainn h1 {
    font-size: 2.3rem;
    padding-top: 1vh;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .descript p{
    width: 80%;
  }
  .work2 {
    border-top: rgb(127, 99, 5) 7px solid;
    width: 100%;
    height: 100%;
    padding-top: 2vh;
  }
}
@media (max-width: 600px) {
  .mainn {
      transform: translateX(-95.5%);
      top: 3vh;
      height: 75vh;
      width: 90vw;
  }
  .mainn img {
    width: 60%;
    border-radius: 5px;
  }
  .mainn h1 {
    font-size: 1.8rem;
    padding-top: 1vh;
    margin-bottom: 0;
}

.mainn h4 {
    font-size: 0.7rem;
    font-weight: 100;
    margin-bottom: 0;
}

  .block1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .descript p{
    font-size: 1rem;
    width: 90%;
  }
  .work2 {
    border-top: rgb(127, 99, 5) 7px solid;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
  }
}
@media (max-width: 400px) {
  .mainn {
      top: 3vh;
      height: 75vh;
      width: 90vw;
  }
  .mainn img {
    width: 50%;
    border-radius: 5px;
  }
  .mainn h1 {
    font-size: 1.3rem;
    padding-top: 1vh;
    margin-bottom: 0;
}

.mainn h4 {
    font-size: 0.5rem;
    font-weight: 100;
    margin-bottom: 0;
}

  .block1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .descript p{
    font-size: 0.8rem;
    width: 90%;
  }
  .work2 {
    border-top: rgb(127, 99, 5) 7px solid;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
  }
}