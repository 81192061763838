.mainModalFrame::before {
    transform: translateX(150%);
}

.mainModalFrame {
    background-color: rgba(36, 36, 36, 0.875);
    position: fixed;
    top: 10%;
    left: 10%;
    height: 80vh;
    width: 80vw;
    box-sizing: border-box;
    margin: auto;
    z-index: 200;
    border: none;
    animation: slideIn 0.4s ease-in-out forwards;
}

.ModalOpen {
    display: block;
}
.ModalClosed {
    display: none;
}

@keyframes slideIn {
    0% {
        transform: translateX(-120vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes slideIn {
    0% {
        transform: translateX(-120vw);
    }
    100% {
        -webkit-transform: translateX(0);
    }
}