.footer {
    position: absolute; left: 0; bottom: 0;
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    list-style: none;
}
.mainFooter__link {
    padding: 1.5rem;
}
.link {
    color: rgba(75, 88, 10, 0.772);
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 139, 0);
    border-style: none;
    font-size: 1rem;
}
.link:hover {
    background-color: rgba(0, 255, 255, 0);
    color: whitesmoke;
}
