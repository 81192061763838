.main {
    margin: auto 0;
}
.nameTransition {
    position: fixed;
    top: 2vh;
    left: -4vw;
}
.nameTransitionH4{
    width: 25vw;
    font-size: 1.3rem;
    margin: 0.3vh 0.3vw;
}
h5 {
    font-size: 1.2rem;
    padding-top: 2vh;
    padding-left: 3vw;
    margin: 0.5vh 0.5vw;
    text-align: left;
}

@media (max-width:1400px) {
    h5 {
        padding-top: 3vh;
        margin-bottom: 0;
    }
}
@media (max-width:1250px) {
    h5 {
        padding-top: 3vh;
        margin-bottom: 0;
    }
}
@media (max-width:850px) {
    .nameTransition {
        display: none;
    }
    .nameTransitionH4 {
        display: none;
    }
    h5 {
        display: none;
    }
}
@media (max-width:1700px) {
    .nameTransition {
        left: 0;
    }
}
@media (max-width:1200px) {
    .nameTransition {
        left: 4vw;
    }
}
@media (max-width:900px) {
    .nameTransition {
        left: 5vw;
    }
}
@media (max-width: 600px) {
    .main {
        display: none;
    }
    .nameTransitionH4 {
        font-size: 1rem;
        font-weight: 200;
    }
    h5 {
        font-size: 0.8rem;
        font-weight: 200;
    }
}