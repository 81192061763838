.slides-enter {
  transform: translateX(110%);
}
.slides-enter-active {
    animation: slideIn 0.4s ease-out ;
    /* transition: transform 1s ease-in-out; */
    transform: translateX(0%);
  }
  .slides-exit {
    transform: translateX(0%);
  }
  .slides-exit-active {
    animation: slideOut 0.4s ease-out forwards;
    transform: translateX(110%);
    /* transition: transform 1s ease-in-out; */
  }
  
  @keyframes slideIn {
    100% { transform: translateX(100%); }
  }
  @-webkit-keyframes slideIn {
    100% { -webkit-transform: translateX(100%); }
  }
   
  @keyframes slideOut {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
  }
  @-webkit-keyframes slideOut {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(100%); }
  }
  