.main {
    background-color: rgba(36, 36, 36, 0.875);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    margin: auto;
    z-index: 200;
    box-sizing: border-box;
}

.BackdropOpen {
    display: block;
}
.BackdropClosed {
    display: none;
}