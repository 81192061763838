.main__emailCP {
    position: fixed;
    top: 70vh;
    left: 30vw;
    width: 25vw;
    height: 15vh;
    border-radius: 15px;
    background-color: rgba(7, 37, 37, 0.933);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.main__emailCP h1 {
    color: rgb(176, 136, 7);
    font-size: 2vh;
    padding: 0.5vh 0.5vw;
    line-height: 3vh;
}

@media (max-width: 800px) {
    .main__emailCP {
        top: 70vh;
        left: 30vw;
        width: 57vw;
        height: 17vh;
    }
    .main__emailCP h1 {
        font-size: 2.5vh;
    }
}
@media (max-width: 400px) {
    .main__emailCP {
        top: 70vh;
        left: 10vw;
        width: 70vw;
        height: 20vh;
    }
}
@media(max-width: 300px) {
    .main__emailCP {
        top: 70vh;
        left: 10vw;
        width: 80vw;
        height: 20vh;
    }
}