.main {
    position: fixed;
    top: 4vh;
    left: 5vw;
    width: 80vw;
    height: 75vh;
    box-sizing: border-box;
    background-color: rgba(34, 32, 19, 0.5);
    padding: 5vh 3vw;
    margin: auto;
    border-radius: 15px;
    transform: translateX(-101%);
    line-height: 3.5vh;
    box-sizing: border-box;
}
.main h1 {
    margin: 5vh 0;
    font-size: 2.5rem;
}
.main h3 {
    font-size: 1.8rem;
}
.main p {
    font-size: 1.5rem;
}
.main h4 {
    font-size: 1.8rem;
}

 .button {
    position: relative;
    top: 5vh;
    left: 0vw;
    margin: auto;
    width: 17rem;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    cursor: pointer;
}
.triangl1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 5rem solid rgba(50, 43, 8, 0.586);
    border-right: 17rem solid transparent;
}
.button__descr {
    position: relative;
    font-size: 1.5rem;
    width: 17rem;
    top: 0rem;
    left: 0;
    color: rgb(173, 141, 36);
    z-index: 5;
    background-color: transparent;
    border: none;
}
.button__descr:hover {
    color: white;
    background-color: transparent;
}
.triangl2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-right: 17rem solid rgba(6, 41, 41, 0.548);
    border-top: 5rem solid transparent;
}
@media (max-width: 1200px) {
    .main {
        left: 10vw;
    }
    .main h1 {
        margin: 1vh 0;
        margin-bottom: 1.5vh;
    }
}
@media (max-width: 800px) {
    .main h1 {
        margin: 1vh 0;
        margin-bottom: 1.5vh;
    }
    .main h3 {
        font-size: 1.5rem;
    }
    .main p {
        font-size: 1.3rem;
    }
    .main h4 {
        font-size: 1.4rem;
    }
}

@media (max-width: 600px) {
    .main {
        padding: 0;
        line-height: 3.2vh;
        top: 3vh;
        height: 70vh;
    }
    
    .main h1 {
        margin: 4vh 0;
        font-size: 1.7rem;
    }
    .main h3 {
        font-size: 1rem;
    }
    .main p {
        font-size: 1rem;
    }
    .main h4 {
        font-size: 1rem;
    }
    .button {
        top: 5vh;
        left: 0vw;
        margin: auto;
        width: 12rem;
        height: 3rem;
    }
    .triangl1 {
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 3rem solid rgb(42, 35, 4);
        border-right: 12rem solid transparent;
    }
    .button__descr {
        position: relative;
        font-size: 1rem;
        width: 17rem;
        top: 0rem;
        left: 0;
        color: rgb(176, 136, 7);
        z-index: 5;
        background-color: transparent;
        border: none;
    }
    .button__descr:hover {
        color: white;
        background-color: transparent;
    }
    .triangl2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-right: 12rem solid rgb(3, 43, 43);
        border-top: 3rem solid transparent;
    }
}
@media (max-width: 300px) {
    .main {
        height: 75vh;
    }
}