.contactMain {
    position: fixed;
    top: 4vh;
    left: 5vw;
    width: 80vw;
    height: 75vh;
    box-sizing: border-box;
    background-color: rgba(34, 32, 19, 0.5);
    margin: auto;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 2.5vh;
    text-align: left;
    z-index: 201;
    transform: translateX(-101%);
}
.contactUnvisibleblock1, 
.contactUnvisibleblock2 {
    box-sizing: border-box;
    padding: 1vh 1vw;
    width: 50%;
    margin: 0;
}
.contactUnvisibleblock2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.contactUnvisibleblock1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30vh;
}
.contactUnvisibleblock2__icons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contactUnvisibleblock1 {
    max-width: 40vw;
    margin: auto;
    text-align: center;
}
.contactUnvisibleblock1 h1 {
    font-size: 2.5vh;
    font-weight: 100;
}
.contactFormButton {
    margin: auto;
}
.contactFormButton button {
    margin: 0.5rem 0.5rem;
    background-color: rgba(34, 32, 19, 0.372);
    border-radius: 10px;
    padding: 1.7vh 2vw;
    font-size: 2.5vh;
    color: rgb(176, 136, 7);
    cursor: pointer;
    border-top: rgb(176, 136, 7) 2px solid;
    border-bottom: rgb(176, 136, 7) 2px solid;
    border-right: transparent;
    border-left: transparent;
}
.contactFormButton button:hover {
    transform: translateY(0.2rem);
}



.contactMain input,
.contactMain textarea {
    background-color: rgba(5.1, 23.5, 23.5, 0.933);
    color: whitesmoke;
    border: none;
    border-radius: 20px;
    outline: none;
}
.contactMain textarea {
    padding: 0.5vh 0.5vw;
    box-sizing: border-box;
}
.contactMain input:focus-visible,
.contactMain textarea:focus-visible {
    border-color: red;
}

.contactUnvisibleblock1_1 {
    padding-bottom: 1vh;
}
.contactUnvisibleblock1_1 input {
    width: 100%;
    border-radius: 5px;
    font-size: 1.2rem;
    margin-bottom: 0.4vh;
}
.formContactMessageTextarea {
    margin-top: 0.3vh;
    width: 100%;
    max-width: 100%;
    height: 13vh;
    max-height: 20vh;
    border-radius: 5px;
    font-size: 1.2rem;
}
.contactFormButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
}
.contactFormButtons button {
    background-color: rgb(176, 136, 7);
    font-size: 1.5rem;
    border-radius: 5px;
    color: whitesmoke;
    padding: 0.3vh 0.3vw;
    cursor: pointer;
}
.contactFormButtons button:hover {
    background-color: whitesmoke;
    color: rgb(176, 136, 7);
}

.contactUnvisibleblock2 {
    padding-bottom: 3vh;
    text-align: center;
    border-left: rgb(127, 99, 5) 7px solid;
}
.picPlus__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contactUnvisibleblock2 .picPlus {
    position: relative;
    top: 5vh;
    width: 20vw;
    margin: 2vh auto;
    border-radius: 15px;
    background: linear-gradient(rgba(5.1, 23.5, 23.5, 0.933), rgba(5.1, 23.5, 23.5, 0.933)) no-repeat;
    filter: brightness(80%);
    
}
.contactUnvisibleblock2__icon_iconsSry {
    padding-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
}
.contactUnvisibleblock2 .fac,
.contactUnvisibleblock2 .inst,
.contactUnvisibleblock2 .github,
.contactUnvisibleblock2 .twitter,
.contactUnvisibleblock2 .linkedin {
    width: 4vw;
    border-radius: 170px;
    cursor: pointer;
}
.contactUnvisibleblock2 .fac:hover,
.contactUnvisibleblock2 .inst:hover,
.contactUnvisibleblock2 .twitter:hover,
.contactUnvisibleblock2 .github:hover,
.contactUnvisibleblock2 .linkedin:hover {
    background-color: rgb(3, 43, 43);
}


@media (max-width: 1200px) {
    .contactMain {
        left: 10vw;
    }
}
@media (max-width: 700px) {
    .contactMain {
        top: 3vh;
    }
    .contactUnvisibleblock2 .picPlus {
        width: 30vw;
    }
    .contactUnvisibleblock2 .fac,
    .contactUnvisibleblock2 .inst,
    .contactUnvisibleblock2 .github,
    .contactUnvisibleblock2 .twitter,
    .contactUnvisibleblock2 .linkedin {
        width: 7vw;   
    }
}
@media (max-width: 600px) {

    .contactMain {
        position: fixed;
        top: 3vh;
        transform: translateX(-98%);
        width: 85vw;
        height: 75vh;
        flex-direction: column;
    }

    .contactUnvisibleblock1,
    .contactUnvisibleblock2 {
        margin: 3vh 0vw;
        padding: 0vh 0vw;
        width: 100%;
    }
    .contactUnvisibleblock1 {
        max-width:80vw;
        margin: auto;
        text-align: center;
    }

    /* .contactUnvisibleblock1 {
        display: none;
    } */

    .contactUnvisibleblock2 {
        padding-bottom: 3vh;
        text-align: center;
        width: 100%;
        height: 34vh;
        border-left: none;
        border-top: rgb(127, 99, 5) 7px solid;
        border-bottom: rgb(127, 99, 5) 7px solid;
        justify-content: space-between;
    }
    .contactUnvisibleblock2 .picPlus__wrap,
    .contactUnvisibleblock2 .picPlus {
        /* width: 50vw; */
        display: none;
    }
    .contactUnvisibleblock2 .fac,
    .contactUnvisibleblock2 .inst,
    .contactUnvisibleblock2 .github,
    .contactUnvisibleblock2 .twitter,
    .contactUnvisibleblock2 .linkedin {
        width: 15vw;   
    }
}
@media(max-width: 300px) {
    .contactMain {
        top: 3vh;
        transform: translateX(-95.5%);
        width: 90vw;
        height: 80vh;
    }

}