.mainIframes::before {
    transform: translateX(150%);
}

.mainIframes {
    position: fixed;
    top: 10%;
    left: 10%;
    height: 80vh;
    width: 80vw;
    box-sizing: border-box;
    margin: auto;
    z-index: 201;
    border: rgb(127, 99, 5) 3px solid;
    animation: slideIn 0.4s ease-in-out forwards;
    border-radius: 20px;
    overflow-x: hidden;
}

.mainIframesFramer {
    position: fixed;
    top: 10%;
    left: 10%;
    height: 80vh;
    width: 80vw;
    box-sizing: border-box;
    margin: auto;
    z-index: 201;
    border: rgb(127, 99, 5) 3px solid;
}
::-webkit-scrollbar {
    width: 7px;
    overflow-y: scroll;
    box-sizing: border-box;
  }

@keyframes slideIn {
    0% {
        transform: translateX(-120vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes slideIn {
    0% {
        transform: translateX(-120vw);
    }
    100% {
        -webkit-transform: translateX(0);
    }
}